import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Seo from '../seo/seo'
const EmptyComponent = props => {
  const intl = useIntl()

  return (
    <>
      <Seo
        title={intl.formatMessage({ id: 'HOME_META_TITLE' })}
        url={props.location.pathname}
        lang={intl.formatMessage({ id: 'MAIN_LANGUAGE' })}
        description={intl.formatMessage({ id: 'HOME_META_DESCRIPTION' })}
      />
    </>
  )
}

export default EmptyComponent
